@import "~@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.unavailable-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7f7f7;
  padding: 20px;
}

img {
  max-width: 100%;  /* Ensures the image never exceeds the width of its container */
  height: auto;     /* Maintains the image's aspect ratio */
  max-height: 80vh; /* Ensures the image doesn't exceed 80% of the viewport height */
}
