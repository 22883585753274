@keyframes ellipsisAnimation {
    0% { content: ""; }
    25% { content: "."; }
    50% { content: ".."; }
    75% { content: "..."; }
    100% { content: ""; }
  }
  
  .ellipsis {
    display: inline-block;
    position: relative;
    width: 1em;
  }
  
  .ellipsis::after {
    content: "";
    display: block;
    animation: ellipsisAnimation 2s infinite;
  }
  