/*
 * Filename: application/frontend/src/themes/halloween.css
 * Description: Halloween CSS for the application
 * Author: Aires Marques
 * History:
 + 2023-10-28 - Documented
 * Notes:
*/

body {
  background-image: url('./halloween.png');
  background-size: cover;  /* Added this line */
  background-repeat: no-repeat;  /* Prevents the image from repeating */
  background-position: center center;  /* Centers the image */
  min-height: 100vh;
  margin: 0;
  background-attachment: fixed;  /* Optional: This will make the background fixed while scrolling */
}

.halloween-theme .card-subtitle, .halloween-theme .card-text {
  color: white ; 
  font-size: calc(6px + 2vmin);
}

  
.halloween-theme .App {
  text-align: center;
  flex-direction: column;
  min-height: 100vh; /* Ensure it covers the full viewport height */

}
  
  .halloween-theme .App-logo {
    filter: grayscale(100%); /* make the logo grayscale */
  }
  
  .halloween-theme .App-header {
    background-color: #1E1E1E; /* dark gray */
    color: #FFC300; /* golden yellow */
  }
  
  .halloween-theme .App-link {
    color: #FF5733; /* Halloween orange */
  }
  
  .halloween-theme .white-text {
    color: #F8E9A1; /* off-white, almost like old parchment */
  }

  .halloween-theme .laundry-chooser {
    background-color: rgba(214, 146, 44, 0.2); /* Slight Halloween tint */
  }
  
  .halloween-theme .laundry-chooser > div {
    flex: 1; /* This will ensure that the div containing the buttons and chat takes up the available space */
    display: flex;
    flex-direction: column;
    }
  
  .halloween-theme .laundry-chooser button {
    background-color: #B45F06; /* Dark orange */
    color: #F8E9A1; /* Off-white */
  }
  


  
  .halloween-theme .product-card {
    background-color: rgba(214, 146, 44, 0.3); 
    color: #ffffff; 
    border: 2px solid #FF5733; /* Halloween orange border */
    font-weight: bold; /* bold text */
    font-size: 1.2em; /* 20% larger font size */
}


  
  .halloween-theme .product-badge {
    width: 30px;
    height: 30px;
    background-color: #8a1616;
    color: #F8E9A1; 
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: px;
    font-weight: bold;
  }
  
  .halloween-theme .justify-button-right {
    text-align: right;
  }
  
  .halloween-theme .d-flex {
    display: flex;
  }
  
  .halloween-theme .justify-content-center {
    justify-content: center;
  }
  
  .halloween-theme .align-items-center {
    align-items: center;
  }
  
  .halloween-theme .toggle-btn-group {
    width: 100%;
  }
  
  .halloween-theme .toggle-btn {
    background-color: #B45F06; /* dark orange, like a pumpkin's shell */
    color: #e3e3e3; /* dark gray */
    border-color: #6B4226; /* dark brown */
    flex-grow: 1;
  }
  
  .halloween-theme .toggle-btn.active {
    background-color: #8D2828; /* blood red */
    color: #F8E9A1; /* off-white */
    border-color: #5079A2 ;

  }
  
/*
 * 
 * PreCheckout.css 
 * 
 */


  .halloween-theme .payment-method-card {
    background-color: rgba(214, 146, 44, 0.3); 
    color: #ffffff; 
    border: 2px solid #FF5733; /* Halloween orange */
  }
  
  .halloween-theme .payment-method-badge {
    background-color: #8D2828; /* blood red */
    color: #F8E9A1; 
  }
  
  .halloween-theme .payment-images img:first-child {
    margin-right: 10px;
  }
  
  .halloween-theme .payment-images img:not(:first-child) {
    margin-left: 1px;
  }
  
  .halloween-theme .payment-images {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .halloween-theme .payment-logo {
    height: 40px;
    width: auto;
    background-color: white ;
    border: 2px solid #6B4226; /* dark brown */
    margin: 5px;
  }
  
.halloween-theme .international-checkout-messages {
  font-size: 28px; /* Adjusted font size for Halloween theme */
  color: white;    /* Text color for Halloween theme */
}

/*++++++++++++++++++++++
 +
 +     Chatbot CSS
 +
 ++++++++++++++++++++++*/

.halloween-theme .chat-container {
  position: relative;
}

.halloween-theme .chat-help-icon {
  color: #F8E9A1; /* Color suggestion */
}

.halloween-theme .chat-modal {
  /* You can add specific styles for the Halloween theme here */
}

.halloween-theme .chat-close-button {
  background-color: #B45F06; /* Dark orange, like a pumpkin's shell */
  color: #F8E9A1; /* Off-white */
}

.halloween-theme .chat-close-button:hover {
  background-color: #8D2828; /* Blood red */
  color: #F8E9A1; /* Off-white */
}

/* Adding missing styles for disabled states */
.halloween-theme .product-card.disabled {
  background-color: rgba(214, 146, 44, 0.3);
  color: #ffffff;
  border: 2px solid #8D2828; /* Dark red for disabled state */
  margin: 0px;
  padding: 0px;
  border-radius: 0;
}

.halloween-theme .product-badge.disabled {
  width: 30px;
  height: 30px;
  background-color: #8D2828; /* Dark red for disabled state */
  color: #F8E9A1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: px;
  font-weight: bold;
}

/* Adding dry options styles */
.halloween-theme .dry-options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.halloween-theme .dry-options-display {
  color: #F8E9A1; /* Halloween off-white */
  font-size: 1.5em;
  min-width: 100px;
  text-align: center;
  padding: 5px 10px;
}

/* Adding instruction styles */
.halloween-theme .black-subtitle {
  color: #F8E9A1; /* Halloween off-white instead of black */
  font-size: 1.8em;
  margin-bottom: 20px;
}

.halloween-theme .black-subheading {
  color: #F8E9A1; /* Halloween off-white instead of black */
  font-size: 1.5em;
  margin-bottom: 10px;
}

.halloween-theme .black-list {
  color: #F8E9A1; /* Halloween off-white instead of black */
  font-size: 1.4em;
  text-align: left;
  padding-left: 20px;
}

/* Update App-logo animation */
@media (prefers-reduced-motion: no-preference) {
  .halloween-theme .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
