/*
 * Filename: application/frontend/src/themes/standard.css
 * Description: Standard CSS for the application
 * Author: Aires Marques
 * History:
 + 2023-10-28 - Documented
 * Notes:
*/


body {
  background: linear-gradient(to top, #289ba3, #0d8bc5); 
  min-height: 100vh;
  margin: 0;
}


.card-subtitle, .card-text {
  color: white ; 
  font-size: calc(6px + 2vmin);
}

.App {
  text-align: center;
  flex-direction: column;
  min-height: 100vh; /* Ensure it covers the full viewport height */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #314045;
}

.white-text {
  color: white;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.laundry-chooser {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
}

.laundry-chooser > div {
  flex: 1; /* This will ensure that the div containing the buttons and chat takes up the available space */
  display: flex;
  flex-direction: column;
}


.laundry-chooser button {
  margin-bottom: 20px; /* Adjust based on your design preference */
}

.product-card {
  background-color: #18303c ;
  color: white ;
  border: 2px solid #28a745 ;
  margin: 0px;
  padding: 0px;
  border-radius: 0 ;
}

.product-badge {
  width: 30px;
  height: 30px;
  background-color: #0ee684;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: px;
  font-weight: bold;
}


.product-card.disabled {
  background-color: #18303c ;
  color: white ;
  border: 2px solid #a72833;
  margin: 0px;
  padding: 0px;
  border-radius: 0 ;
}

.product-badge.disabled {
  width: 30px;
  height: 30px;
  background-color: #e60e4b;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: px;
  font-weight: bold;
}


.justify-button-right {
  text-align: right;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

/* Store.css */

.toggle-btn-group {
  width: 100%;
}

.toggle-btn {
  background-color: lightgrey ;
  color: rgb(1, 0, 17) ;
  border-color: #0b2e52 ;
  flex-grow: 1;
}

.toggle-btn.active {
  background-color: #0ee684 ; /* Zima Blue */
  color: rgb(7, 86, 105) ;
  border-color: #5079A2 ;
  
}

/*
 * 
 * PreCheckout.css 
 * 
 */

.payment-method-card {
  background-color: #18303c ;
  margin-bottom: 20px; /* gives spacing between consecutive cards */
  border: 2px solid #28a745 ; /* Correct green color */
  color: white !important; /* Setting text color to white */

}

.payment-method-badge {
  width: 200px;
  height: 100px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: px;
  font-weight: bold;
}

.payment-images img:first-child {
  margin-right: 10px;  /* reduced to ensure even spacing */
}

.payment-images img:not(:first-child) {
  margin-left: 1px;
}

.payment-images {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.payment-logo {
  height: 40px;
  width: auto;
  background-color: white !important;
  border: 2px solid white;  /* red border around each image */
  margin: 5px; /* Margin around each image */
}

.international-checkout-messages {
  font-size: 28px; /* Adjusted font size for Halloween theme */
  color: white;    /* Text color for Halloween theme */
}


/*++++++++++++++++++++++
 +
 +     DRY options
 +
 ++++++++++++++++++++++*/

.dry-options-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


  .dry-options-display {
  color: white;
  font-size: 1.5em; /* Larger font size for better readability */
  min-width: 100px; /* Ensure display does not shrink */
  text-align: center; /* Center the text */
  padding: 5px 10px; /* Padding for the display area */
  }
  




/*++++++++++++++++++++++
 +
 +     Chatbot CSS
 +
 ++++++++++++++++++++++*/

.chat-container {
  position: relative;
}

.chat-help-icon {
  position: absolute;
  top: 10px;          /* Adjust as per your requirement */
  right: 10px;        /* Adjust as per your requirement */
  cursor: pointer;
  font-size: 36px;    /* You can adjust this to change the size of the SOS emoji */
  z-index: 10;        /* Ensure it appears above other elements */

}

.chat-modal {
  position: relative;
}

.chat-close-button {
  background-color: lightgrey;
  color:  #18303c;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  position: absolute;
  top: -40px;          /* Position it above the ChatContainer */
  right: 0;            /* Align it to the right */
  z-index: 10;         /* Ensure it appears above the ChatContainer */
}

.chat-close-button:hover {
  background-color:  #0ee684;
  color : #18303c;
}

/*++++++++++++++++++++++
 +
 +     Instructions CSS
 +
 ++++++++++++++++++++++*/

/* Added for SuccessDry component */

/* Black text with larger font size for subtitles */
.black-subtitle {
  color: black;
  font-size: 1.8em; /* Adjust as needed */
  margin-bottom: 20px;
}

/* Black text with larger font size for subheadings */
.black-subheading {
  color: black;
  font-size: 1.5em; /* Adjust as needed */
  margin-bottom: 10px;
}

/* Black text with increased font size for list items */
.black-list {
  color: black;
  font-size: 1.4em; /* Adjust as needed */
  text-align: left;
  padding-left: 20px;
}

.google-maps-link {
  color: #1a0dab; /* Standard link color */
  text-decoration: none; /* Remove underline */
  position: relative;
  padding-right: 30px; /* Space for the icon */
}

.google-maps-link::after {
  content: '🔗'; /* Link icon */
  position: absolute;
  right: 0;
  top: 0;
}